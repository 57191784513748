.scene {
  width: 150px;
  height: 150px;
  perspective: 500px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.cardFace {
  position: absolute;
  height: 100%;
  width: 100%;
}
.cardFaceFront {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 4px #61757b;
  cursor: pointer;
  background-color: rgb(140, 170, 227);
  position: relative;
}

.cardFaceBack {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 4px #61757b;
  cursor: pointer;
  background-color: white;
  transform: rotateY(180deg);
  position: relative;
}
.card.is-flipped {
 transform: rotateY(180deg);
}

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
.App {
  text-align: center;
 font-family: 'Caveat', cursive;
}

.Deck {
  display: grid;
  grid-template-columns: repeat(4,160px);
  grid-template-rows: repeat(4,160px);
  justify-content: center;
}

.reshuffle {
  position: relative;
  width: 150px;
  height: 100px;
  font-family: 'Caveat', cursive;
  font-size: xx-large;
  background-color: rgb(140, 170, 227);
  border-radius: 10px;
}
h1 {
  margin-bottom: 0px;
}
h2{
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.scoreWindow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}